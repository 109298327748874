import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

type ReturnType = Record<string, string | number>;

interface HookParams {
  parseNumeric?: boolean;
}

export default function useURLParams({ parseNumeric = false }: HookParams = {}) {
  const location = useLocation();

  const params = useMemo(() => {
    const result: ReturnType = {};
    const urlParams = new URLSearchParams(location.search);
    const urlParamsEntries = Array.from(urlParams.entries());

    for (const [key, value] of urlParamsEntries) {
      if (parseNumeric) {
        result[key] = (/^\d+$/).test(value) ? Number(value) : value;
      } else {
        result[key] = value;
      }
    }

    return result;
  }, [location.search, parseNumeric]);

  return params;
}
