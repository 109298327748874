import * as Sentry from '@sentry/react';

interface SentryErrorBoundaryProps {
  children: React.ReactNode;
}

const dialogOptions: Sentry.ReportDialogOptions = {
  user: {
    email: window?.user?.email || undefined,
    // name?: string;
  },
  title: '😔 Что-то поломалось...',
  subtitle: 'На сайте произошла критическая ошибка. Мы уже работаем над исправлением.',
  subtitle2: 'Помогите нам ее решить – расскажите, что случилось:',
  labelName: 'Ваше имя',
  labelEmail: 'Ваша почта',
  labelComments: 'Как это произошло?',
  labelClose: 'Закрыть',
  labelSubmit: 'Отправить отчет о сбое',
  lang: 'ru',
  onClose: () => {
    window.location.href = window.location.origin;
  },
  onError: () => {
    // цель на метрику
  },
};

export default function SentryErrorBoundary(props: SentryErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary showDialog dialogOptions={dialogOptions}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
