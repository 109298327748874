import { type ISODateString } from '@/types/types';
import { createSlice } from '@reduxjs/toolkit';

export interface Action<P> {
  type: string;
  payload: P;
}

export interface ParsingProgress {
  percent: number;
  total: number;
  complete: number;
  begin: ISODateString | null;
  listId: string | null;
  listName: string | null;
  status: 'stop' | 'process' | 'pause';
}

const initialState: ParsingProgress = {
  percent: 0,
  total: 0,
  complete: 0,
  begin: null,
  listId: null,
  listName: null,
  status: 'stop',
};

export const parsingProgressSlice = createSlice({
  name: 'parsingProgressSlice',
  initialState,
  reducers: {
    setParsingProgress: (state, action: Action<ParsingProgress>) => ({
      ...state,
      ...action.payload,
    }),
    reset: () => ({ ...initialState }),
  },
});

export const { setParsingProgress, reset } = parsingProgressSlice.actions;

export default parsingProgressSlice.reducer;
