import { createSlice } from '@reduxjs/toolkit';

import { type ProjectArticle } from '../api/project-articles';

// todo: вынести в отдельный глобальный тип и заюзать везде
export interface Action<P> {
  type: string;
  payload: P;
}

interface ProjectArticlesSliceType {
  articles: ProjectArticle[];
}

const initialState = {
  articles: [],
} as ProjectArticlesSliceType;

export const projectArticlesSlice = createSlice({
  name: 'projectArticlesSlice',
  initialState,
  reducers: {
    setStorePojectArticles: (state, action: Action<P>) => ({
      ...state,
      articles: action.payload,
    }),
  },
});

export const { setStorePojectArticles } = projectArticlesSlice.actions;

export default projectArticlesSlice.reducer;
