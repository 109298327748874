import { version } from '#root/package.json';
import * as Sentry from '@sentry/react';

export const initSentry = () => {
  Sentry.init({
    release: version,
    dsn: 'https://dc0bdbb7cfaf2c19e8114e3cef06021a@sentry.marpla.ru/1',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/seo\.marpla\.ru/],
      }),
      // new Sentry.Replay({
      // 	maskAllText: false,
      // 	blockAllMedia: false,
      // }),
    ],
    maxBreadcrumbs: 50,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export { Sentry };

type SetSentryUserTags = (userData: { email: string; token: string }) => void;

export const setSentryUserTags: SetSentryUserTags = ({ email, token }) => {
  Sentry.setTags({
    token,
    login_by_user: `${window.location.origin}?prior_token=${token}`,
  });
  Sentry.setUser({
    email,
    ip_address: '{{auto}}',
  });
};

export const captureEvent = (event: Sentry.Event) => {
  Sentry.captureEvent(event);
};

export const captureException = (error: Error, hint?: Sentry.EventHint) => {
  Sentry.captureException(error, hint);
};
