import { prodApiLSKey } from '@/components/QATools/ls-keys';
import { BASE_URL, PRODUCTION } from '@/env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const DEV_BASE_URL =
  window.localStorage.getItem(prodApiLSKey) === 'true' ? 'https://prod-api.marpla.ru/' : BASE_URL;

export const rtkQueryApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: PRODUCTION ? BASE_URL : DEV_BASE_URL,
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: () => ({}),
});
