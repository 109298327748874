export interface VersionInfo {
  version: string;
}

const packageJsonVersion = window.version;
const enabled = !import.meta.env.DEV;
const interval = +import.meta.env.VITE_CHECK_VERSION_INTERVAL_SECONDS * 1000;
let checkCount = -1;

async function fetchVersion(): Promise<VersionInfo> {
  const response = await fetch(`${window.location.origin}/version.json?no-cache=${Date.now()}`, {
    cache: 'no-cache',
  });

  return response.json() as Promise<VersionInfo>;
}

function checkAndReloadIfNewVersion(): void {
  checkCount += 1;

  fetchVersion()
    .then((versionInfo) => {
      if (versionInfo.version === packageJsonVersion) {
        setTimeout(checkAndReloadIfNewVersion, interval);

        return;
      }

      if ('navigation' in window && checkCount > 0) {
        (window.navigation as any).addEventListener('navigate', () => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });

        return;
      }

      window.location.reload();
    })
    .catch((err) => {
      console.error('Ошибка при проверке версии сборки:', err);
      setTimeout(checkAndReloadIfNewVersion, interval);
    });
}

if (enabled) {
  checkAndReloadIfNewVersion();
}
