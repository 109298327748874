import { isEmpty } from '@/utils/utils';
import IconButton from '@marpla/ui/components/IconButton';
import Typography from '@marpla/ui/components/Typography';
import CloseIcon from '@marpla/ui/icons/Close';
import Box from '@mui/material/Box';
import { type BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { useEffect, useState } from 'react';

import styles from './index.module.scss';

export type NorificationProps = Omit<BoxProps, 'children'> & {
  children?: string | null;
  innerHtml?: string | null;
};

export default function Notification({ children, innerHtml, ...rest }: NorificationProps) {
  const [isCollapsed, setIsCollapsed] = useState(isEmpty(children));

  const content = children || innerHtml;
  useEffect(() => {
    setIsCollapsed(isEmpty(content));
  }, [content]);

  if (!content) {
    return null;
  }

  let render = null;

  if (children) {
    render = (
      <Typography variant="bodyRegular" component="div">
        {children}
      </Typography>
    );
  } else if (innerHtml) {
    render = (
      <Typography variant="bodyRegular" component="div">
        <span dangerouslySetInnerHTML={{ __html: innerHtml }} />
      </Typography>
    );
  }

  return (
    <Collapse in={!isCollapsed}>
      <Box className={styles.wrapper} {...rest}>
        <IconButton
          className={styles.closeButton}
          variant="ghost"
          rounded
          size="sm"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <CloseIcon />
        </IconButton>

        {render}
      </Box>
    </Collapse>
  );
}
