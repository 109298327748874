import axios from 'axios'
import axiosRetry from 'axios-retry'

import {prodApiLSKey} from '@/components/QATools/ls-keys'

import {BASE_URL, PRODUCTION} from '@/env'

const DEV_BASE_URL =
	window.localStorage.getItem(prodApiLSKey) === 'true'
		? 'https://prod-api.marpla.ru/'
		: BASE_URL

const request = axios.create({
	baseURL: PRODUCTION ? BASE_URL : DEV_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
})

axiosRetry(request, {
	retries: 3,
})

export default request
