import { type ISODateString } from '@/types/types';
import { format, formatISO, parseISO, sub, subDays } from 'date-fns';

export const ISODateStringToddMM = (_ISODateString: ISODateString): string =>
  format(new Date(_ISODateString), 'dd.MM');

const ISODateStringToHmm = (_ISODateString: ISODateString): string =>
  format(new Date(_ISODateString), 'H:mm');

export const getDateHeaderName = (_ISODateString: ISODateString) => {
  if (_ISODateString.includes('00:00:00')) {
    return ISODateStringToddMM(_ISODateString);
  }

  return ISODateStringToHmm(_ISODateString);
};

export const cutHours = (_ISODateString: ISODateString) => _ISODateString.substring(0, 10);

export const isHistoryTime = (_ISODateString: ISODateString) =>
  !_ISODateString.includes('00:00:00');

export const formattedDateToYYMMDD = (_ISODateString: ISODateString) => {
  const date = parseISO(_ISODateString);
  const formattedDate = formatISO(date, { representation: 'date' });

  return formattedDate;
};

export const isValidDateString = (str: string) => String(new Date(str)) !== 'Invalid Date';

export const getUTCFromISOString = (dateStr: string) => {
  const date = new Date(dateStr);
  const utcDate = sub(date, { minutes: new Date().getTimezoneOffset() });

  return utcDate;
};

export const generatePastDates = (count = 30) => {
  const today = new Date();
  const pastDates: string[] = [];

  for (let i = 0; i < count; i++) {
    const pastDate = subDays(today, i);
    const formattedDate = format(pastDate, 'yyyy-MM-dd');
    pastDates.push(formattedDate);
  }

  return pastDates.reverse();
};
