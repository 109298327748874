import './styles/global.scss';
import './preload';

import App from '@/App';
import QATools from '@/components/QATools';
import SentryErrorBoundary from '@/components/SentryErrorBoundary';
import { initSentry } from '@/utils/sentry';
import SnackbarProvider from '@marpla/ui/components/SnackbarProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from './redux/store';
import theme from './theme';
import { disableDataGridLicenseError } from './utils/utils';

initSentry();
enableMapSet();
disableDataGridLicenseError();

document.addEventListener('DOMContentLoaded', () => {
  const rootEl = document.getElementById('root')!;
  const root = ReactDOM.createRoot(rootEl);

  root.render(
    <SentryErrorBoundary>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <SnackbarProvider>
                <CssBaseline />
                <App />
                <QATools />
              </SnackbarProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ReduxProvider>
    </SentryErrorBoundary>,
  );
});
