import useApi from '@/api/useApi';
import Header from '@/components/Header';
import NotificationBackend from '@/components/NotificationBackend';
import { PROJECT_NAME } from '@/env';
import useCommonAuth from '@/hooks/useCommonAuth';
import useURLParams from '@/hooks/useURLParams';
import { setGPTMessagesLimit } from '@/redux/slices/gpt-slice';
import { setUser } from '@/redux/slices/user-slice';
import { captureEvent, setSentryUserTags } from '@/utils/sentry';
import arrayEnd from '@marpla/helpers/arrayEnd';
import Container from '@mui/material/Container';
import { type ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import styles from './index.module.scss';
import { WS_AGENT, WS_GATEWAY_URL, emailToSimpleId } from '../Constants';

interface Page {
  name: string;
  url: string;
  component: React.LazyExoticComponent<(props: { url: string }) => JSX.Element>;
  private: boolean;
}

const allPages: Page[] = [
  {
    name: 'Отчет по товару',
    url: '/report/project/:project/product/:article',
    component: lazy(() => import('@/pages/report/App')),
    private: true,
  },
  {
    name: 'Список проектов',
    url: '/report/project',
    component: lazy(() => import('@/pages/projects/App')),
    private: true,
  },
  {
    name: 'Список артикулов',
    url: '/report/project/:project',
    component: lazy(() => import('@/pages/articles/App')),
    private: true,
  },
  {
    name: 'Финансы',
    url: '/finance',
    component: lazy(() => import('@/pages/finance/App')),
    private: true,
  },
  {
    name: 'Продажи',
    url: '/sales',
    component: lazy(() => import('@/pages/sales/App')),
    private: true,
  },
  {
    name: 'Партнерка',
    url: '/partner',
    component: lazy(() => import('@/pages/partner/App')),
    private: true,
  },
  {
    name: 'Бидменеджер',
    url: '/bidmanager',
    component: lazy(() => import('@/pages/bidmanager/App')),
    private: true,
  },
  {
    name: 'A/B тесты',
    url: '/abtests',
    component: lazy(() => import('@/pages/abtests/App')),
    private: true,
  },
  {
    name: 'Вход',
    url: '/signin',
    component: lazy(() => import('@/pages/signin/App')),
    private: false,
  },
  {
    name: 'Регистрация',
    url: '/signup',
    component: lazy(() => import('@/pages/signup/App')),
    private: false,
  },
  {
    name: 'Восстановление пароля',
    url: '/forgot-password',
    component: lazy(() => import('@/pages/forgot-password/App')),
    private: false,
  },
  {
    name: 'Смена пароля',
    url: '/change-password',
    component: lazy(() => import('@/pages/change-password/App')),
    private: false,
  },
  {
    name: 'Подтверждение регистрации',
    url: '/approve',
    component: lazy(() => import('@/pages/approve-registration/App')),
    private: false,
  },
];
const publicPages = allPages.filter((page) => !page.private);
const privatePages = allPages.filter((page) => page.private);

const initialPathname = window.location.pathname;

function App(): ReactNode {
  // todo: избавыиться от useApi по максимуму
  const {
    isAuthorized: isAuthorizedApi,
    token,
    setToken,
    getUserData,
    resetToken,
    getAccessSensitiveData,
    getFinanceInfo,
  } = useApi();
  useCommonAuth();

  const { prior_token: priorToken } = useURLParams() as { prior_token: string };

  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthorized = isAuthorizedApi();

  // useEffect(() => {
  //   WS_AGENT.pingStart();

  //   const destroy = WS_AGENT.listen(WS_GATEWAY_URL, () => ({
  //     clientId: WS_CLIENT_ID,
  //   }));

  //   return () => {
  //     WS_AGENT.pingStop();
  //     destroy();
  //   };
  // }, []);

  useEffect(() => {
    if (priorToken) {
      setToken({ token: priorToken, isSession: false });
      window.location.href = initialPathname;
    }
  }, [setToken, priorToken]);

  const [currentPage, setCurrentPage] = useState<Page | null>(null);

  useEffect(
    () => setCurrentPage(allPages.find((item) => item.url === location.pathname) || null),
    [location.pathname],
  );

  const title = currentPage ? currentPage.name : PROJECT_NAME;

  useEffect(() => {
    if (!isAuthorized) {
      return;
    }

    (async () => {
      const { email, phone, reg_date, code, question_limit } = (await getUserData()) as {
        email: string;
        phone: string;
        reg_date: string;
        code: string;
        question_limit: number;
      };

      if (!email && !phone) {
        captureEvent({
          message: 'Error when receiving user data',
          level: 'error',
        });
      }

      WS_AGENT.pingStart();

      WS_AGENT.listen(WS_GATEWAY_URL, () => ({
        clientId: emailToSimpleId(email),
      }));

      if (typeof code === 'number') {
        resetToken();
        window.location.href = '/signin';

        return;
      }

      window.jivo_onLoadCallback = () => {
        window.jivo_api.setContactInfo({
          name: email,
          description: window.location.href,
        });
      };

      window.user = {
        email,
        phone,
      };
      setSentryUserTags({
        email,
        token,
      });
      dispatch(
        setUser({
          email,
          phone,
          registrationDate: reg_date,
          isLoadingUserBasicData: false,
        }),
      );
      dispatch(setGPTMessagesLimit(question_limit));
    })().catch(console.error);

    (async () => {
      const {
        unique_queries: uniquePhrases,
        projects,
        queries,
        sales_month: salesMonth,
      } = (await getAccessSensitiveData()) as {
        unique_queries: number;
        projects: number;
        queries: number;
        sales_month: number;
      };

      dispatch(
        setUser({
          uniqueQueries: uniquePhrases,
          projects,
          phrases: queries,
          salesMonth,
          isLoadingAccessSensitiveData: false,
        }),
      );

      const finance = (await getFinanceInfo()) as {
        promo_days: {
          days: number;
          hours: number;
          minutes: number;
          totalMinutes: number;
        };
        tariffs: any;
        tariff_free_days: number;
        balance: number;
        unique_phrases: number;
      };

      const financeInfo: {
        balance: number;
        tariffs: {
          phrase_count_min: number;
          phrase_count_max: number;
          value: number;
          title: string;
          price_per_day: number;
          price: number;
        }[];
        tariff_free_days: number;
        promo_days: {
          days: number;
          hours: number;
          minutes: number;
          totalMinutes: number;
          hasTime: boolean;
        };
        unique_phrases: number;
      } = { ...finance };

      financeInfo.promo_days.totalMinutes =
        finance.promo_days.days * 24 * 60 * 60 +
        finance.promo_days.hours * 60 * 60 +
        finance.promo_days.minutes;
      financeInfo.promo_days.hasTime = finance.promo_days.totalMinutes > 0;

      financeInfo.tariffs = Object.entries(
        finance.tariffs as { [key: string]: { [key: string]: any } },
      ).map(([key, value], index: number) => ({
        ...value,
        phrase_count_min: value.phrase_count_min === 1 ? 0 : value.phrase_count_min, // todo fix on backend
        value: index + 1,
        title: key,
        price_per_day: Math.floor(value.price / 30),
      }));

      let currentTariff = financeInfo.tariffs.find(
        (item) =>
          financeInfo.unique_phrases >= item.phrase_count_min &&
          financeInfo.unique_phrases <= item.phrase_count_max,
      );
      !currentTariff && (currentTariff = arrayEnd(financeInfo.tariffs)!);

      const dayPrice = Math.floor(currentTariff.price / 30);

      dispatch(
        setUser({
          balance: financeInfo.balance,
          tariffs: financeInfo.tariffs,
          dayPrice,
          tariffFreeDays: financeInfo.tariff_free_days,
          bonusTime: financeInfo.promo_days,
          isLoadingFinanceData: false,
        }),
      );
    })().catch(console.error);

    return () => {
      WS_AGENT.pingStop();
    };
  }, [
    getUserData,
    getAccessSensitiveData,
    getFinanceInfo,
    isAuthorized,
    resetToken,
    dispatch,
    token,
  ]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          isAuthorized
            ? (
              <>
                <Helmet>
                  <title>{title}</title>
                </Helmet>
                <Container className={styles.container} maxWidth={false}>
                  <Header />
                  <NotificationBackend mt={2} />

                  <Routes>
                    {privatePages.map(({ url, component: Component }) => (
                      <Route
                        key={url}
                        path={url}
                        element={
                          <Suspense>
                            <Component url={url} />
                          </Suspense>
                        }
                      />
                    ))}

                    <Route path="*" element={<Navigate to="/report/project" />} />
                  </Routes>
                </Container>
              </>
            )
            : location.pathname !== '/forgot-password' && <Navigate to="/signin" />

        }
      />

      {publicPages.map(({ url, component: Component }) => (
        <Route
          key={url}
          path={url}
          element={
            <Container className={styles.container} maxWidth={false}>
              <Suspense>
                <Component url={url} />
              </Suspense>
            </Container>
          }
        />
      ))}
    </Routes>
  );
}

export default App;
