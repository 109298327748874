// import { BM_MODE } from '@/env';

import { rtkQueryApi } from './rtk-query-api';

export const otherApi = rtkQueryApi
  .enhanceEndpoints({ addTagTypes: ['UserFinance'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMoscowDateTime: builder.query<{ datetime: string }, any>({
        query: () => 'seo/get-moscow-date-time',
      }),
      getBmAccessToken: builder.query<any, any>({
        query: () => ({
          url: '/user/get-bm-token',
          method: 'post',
          body: {
            env: 'prod',
          },
        }),
      }),
    }),
  });

export const {
  useLazyGetMoscowDateTimeQuery,
  useGetMoscowDateTimeQuery,
  useLazyGetBmAccessTokenQuery,
} = otherApi;
