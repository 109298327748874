import EventEmitter from 'eventemitter3';

export function createPublicPromise<T>(): PublicPromise<T> {
  let resolve: (value: T) => void;
  let reject: (err?: any) => void;

  const promise = new Promise<T>((r, t) => {
    resolve = r;
    reject = t;
  }) as PublicPromise<T>;

  promise.resolve = resolve!;
  promise.reject = reject!;

  return promise;
}

export type PublicPromise<T> = Promise<T> & {
  resolve: (value: T) => void;
  reject: (err?: any) => void;
};

export const delay = (ms: number): Promise<void> => new Promise((r) => setTimeout(r, ms));

export class WsException extends Error {
  constructor(
    message: string,
    readonly details?: any,
  ) {
    super(message);
  }
}

export class WsAgent {
  static RECONNECTION_DELAY = 5000;
  static PING_INTERVAL = 3000;

  private _pingInterval: any;
  private _socket: WebSocket | null = null;

  private _rejectPromise = createPublicPromise<never>();

  private _listening = false;

  events = new EventEmitter();

  listen(url: string, getConnectPayload?: () => { [key: string]: any }): () => void {
    (async () => {
      this._listening = true;

      if (this._socket && this._socket.readyState !== WebSocket.CLOSED) {
        this._socket.close();
        this.events.emit('close', null);
      }

      try {
        const socket = new WebSocket(url);

        this._socket = socket;

        await new Promise<void>((resolve, reject) => {
          function onOpen(): void {
            resolve();
            socket.removeEventListener('open', onOpen);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            socket.removeEventListener('error', onError);
          }

          function onError(ev: Event): void {
            reject(new WsException('Connection error', ev));
            socket.removeEventListener('open', onOpen);
            socket.removeEventListener('error', onError);
          }

          socket.addEventListener('open', onOpen);
          socket.addEventListener('error', onError);
        });

        this.events.emit('open', null);

        if (getConnectPayload) {
          await this.emit('connect', getConnectPayload());
        }

        const contentIterator = this._createIterator(this._socket);

        for await (const content of contentIterator) {
          const { event, data } = content;

          this.events.emit(event, data);
        }
      } catch (err: any) {
        console.error(err);

        this.events.emit('error', { message: err.message });
        this.events.emit('close', null);

        if (!this._listening) {
          return;
        }

        await delay(WsAgent.RECONNECTION_DELAY);

        this.listen(url, getConnectPayload);
      }
    })().catch(console.error);

    return () => {
      this._listening = false;
      this._socket?.close();
    };
  }

  pingStart(): void {
    this._pingInterval = setInterval(() => {
      void this.emit('ping', {}).catch(console.error);
    }, 3000);
  }

  pingStop(): void {
    clearInterval(this._pingInterval);
  }

  async emit(event: string, data: any): Promise<void> {
    if (!this._listening) {
      return;
    }

    const readyState = this._socket?.readyState;

    if (readyState !== WebSocket.OPEN) {
      console.log('Ready State is not open. Skipping...');

      if (readyState !== WebSocket.CONNECTING) {
        this._rejectPromise.reject(new Error('Restart the connection'));
        this._rejectPromise = createPublicPromise<never>();
      }

      await new Promise<void>((r) => this.events.once('open', r));
    }

    const message = JSON.stringify({ event, data });

    this._socket!.send(message);
    this.events.emit(event, data);
  }

  close(): void {
    if (this._socket?.readyState === WebSocket.CLOSED) {
      console.log('Already closed');

      return;
    }

    this._socket?.close();
  }

  private _createIterator(socket: WebSocket): AsyncIterableIterator<WsMessage> {
    type EventIteratorReturn = Promise<IteratorResult<WsMessage>>;

    let resolve: ((value: IteratorResult<WsMessage>) => void) | null = null;
    let reject: ((err: any) => void) | null = null;

    function messageListener(event: MessageEvent<string>): void {
      try {
        resolve?.({ done: false, value: JSON.parse(event.data) });
        resolve = null;
      } catch (err: any) {
        reject?.(new WsException('Fail to parse data', err));
      }
    }

    function errorListener(err: Event): void {
      reject?.(new WsException('Connection error', err));
      reject = null;
    }

    socket.addEventListener('message', messageListener);
    socket.addEventListener('error', errorListener);

    this._rejectPromise.catch(errorListener);

    return {
      [Symbol.asyncIterator]() {
        return this;
      },
      next(): EventIteratorReturn {
        return new Promise<IteratorResult<WsMessage>>((res, rej) => {
          resolve = res;
          reject = rej;
        });
      },
      return(): EventIteratorReturn {
        socket.removeEventListener('message', messageListener);
        socket.removeEventListener('error', errorListener);

        return Promise.resolve({
          done: true,
          value: { event: 'close', data: null },
        });
      },
      throw(err: any): EventIteratorReturn {
        socket.removeEventListener('message', messageListener);
        socket.removeEventListener('error', errorListener);

        return Promise.reject(new WsException('Unknown error', err));
      },
    };
  }
}

export interface WsMessage {
  event: string;
  data: any;
}
